import React, { PropTypes } from 'react';
import TOCBuilder from './toc_builder';
import Lesson from './lesson';
import SectionHeader from './section_header';
import { getSeparatorColors } from '../../components/helpers/colors';

export default class LessonsList extends React.Component {

  static propTypes = {
    lessons: PropTypes.object,
    sidebarLayout: PropTypes.bool,
    bgColor: PropTypes.string,
    sidebarBgColor: PropTypes.string,
  }

  static defaultProps = {
    bgColor: 'white',
  }

  render() {
    const builder = new TOCBuilder();
    const sections = builder.lessonsWithSections(this.props.lessons);
    const separatorColors = getSeparatorColors(this.props.sidebarBgColor);

    return (
      <div className='toc-section1'>
        {sections.map((section, sectionIndex) => (
          <ul className='lesson--list' key={section.id} style={{ borderColor: separatorColors }} >
            <div key={section.id}>
              <SectionHeader
                index={section.index}
                initialName={section.initialName}
                first={section.first}
                firstLessonIndex={section.firstLessonIndex}
                firstLessonHref={section.firstLessonHref}
                lessonsCount={section.lessons.length}
              />
              {section.lessons.map((lesson, lessonIndex) => (
                <Lesson
                  key={lesson.id}
                  itemClasses={this.props.sidebarLayout ? 'toc-item' : 'toc-item toc-item-spaced'}
                  idx={lesson.idx}
                  id={lesson.id}
                />
              ))}
            </div>
          </ul>
        ))}
      </div>);
  }
}
