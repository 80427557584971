import JpgIcon from './JpgIcon';
import ExcelIcon from './ExcelIcon';
import Mp3Icon from './Mp3Icon';
import PdfIcon from './PdfIcon';
import PptIcon from './PptIcon';
import WaveIcon from './WaveIcon';
import WordIcon from './WordIcon';
import UndoIcon from './UndoIcon';
import RedoIcon from './RedoIcon';
import RefreshIcon from './RefreshIcon';
import RefreshForbiddenIcon from './RefreshForbiddenIcon';
import FlashCloseIcon from './FlashCloseIcon';
import FlashSuccessIcon from './FlashSuccessIcon';
import FlashWarningIcon from './FlashWarningIcon';
import FlashErrorIcon from './FlashErrorIcon';

export {
  JpgIcon,
  ExcelIcon,
  Mp3Icon,
  PdfIcon,
  PptIcon,
  WaveIcon,
  WordIcon,
  RefreshForbiddenIcon,
  UndoIcon,
  RedoIcon,
  RefreshIcon,
  FlashCloseIcon,
  FlashSuccessIcon,
  FlashWarningIcon,
  FlashErrorIcon
};
