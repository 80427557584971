import React, { PropTypes } from 'react';
import { connect }  from 'react-redux';

class _Form extends React.Component {
  static propTypes = {
    for: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    method: PropTypes.string,
    urls: PropTypes.object.isRequired,
    formProps: PropTypes.object,
    token: PropTypes.string.isRequired,
    customDomain: PropTypes.bool.isRequired,
    className: PropTypes.string,
    onSubmit: PropTypes.func,
    children: PropTypes.node,
  };

  static defaultProps = {
    formProps: {},
    method: 'POST',
    className: '',
  };

  static childContextTypes = {
    prefix: PropTypes.string,
  };

  constructor(props) {
    super(props);
    if (props.for === 'user') {
      this.prefix = (props.customDomain ? 'd_school__user' : 'r_school_user');
    } else {
      this.prefix = props.for;
    }
  }

  getChildContext() {
    return {
      prefix: this.prefix,
    };
  }

  render() {
    const { method, token, children, onSubmit } = this.props;
    const action = this.props.urls.getIn(this.props.action.split('.'));
    const specialMethod = ((method.toLowerCase() !== 'get') && (method.toLowerCase() !== 'post'));

    return (
      <form {...this.props.formProps} action={action} method={specialMethod ? 'POST' : method} className={this.props.className} onSubmit={onSubmit}>
        {specialMethod && <input type="hidden" name="_method" value={method} />}
        <input name="authenticity_token" type="hidden" value={token} />
        {children}
      </form>
    );
  }
}

function _name(context, key) {
  if(context.prefix === undefined){
    return `${key}`;
  }else{
    return `${context.prefix}[${key}]`;  
  }
}

export function Label(props, context) {
  return <label {...props}>{props.children}</label>
}

export function PasswordField(props, context) {
  const inputProps = Object.assign(
    {},
    props,
    { type: 'password', name: _name(context, props.name) }
  );

  return (
    <input {...inputProps} />
  );
}

PasswordField.propTypes = {
  name: PropTypes.string.isRequired,
};

PasswordField.contextTypes = {
  prefix: PropTypes.string,
};


export function HiddenField(props, context) {
  const inputProps = Object.assign(
    {},
    props,
    { type: 'hidden', name: _name(context, props.name) }
  );

  return (
    <input {...inputProps} />
  );
}

HiddenField.propTypes = {
  name: PropTypes.string.isRequired,
};

HiddenField.contextTypes = {
  prefix: PropTypes.string,
};

export class Input extends React.Component {

  constructor(props) {
    super(props);
    this.clearValidation = this.clearValidation.bind(this);
    this.setValidityMessage = this.setValidityMessage.bind(this);
  }

  clearValidation() {
    if (!this.el.validity.typeMismatch) {
      this.el.setCustomValidity('');
    }
  }

  setValidityMessage() {
    this.el.setCustomValidity(this.props.customValidity);
  }

  render() {
    const props = this.props;
    const context = this.context;

    const inputProps = Object.assign({}, props, { name: _name(context, props.name) });
    if (props.customValidity) {
      inputProps.onChange = this.clearValidation;
      inputProps.onInvalid = this.setValidityMessage;
    }

    return (
      <input
        {...inputProps}
        ref={(el) => { this.el = el; }}
      />
    );
  }
}

Input.contextTypes = {
  prefix: PropTypes.string,
};


export function TextWithLabel(props, context) {
  return (
    <div className='form-group'>
      <label>{props.label}
        <input type="text" name={_name(context, props.name)} defaultValue={props.defaultValue} />
      </label>
    </div>
  );
}

TextWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  type: PropTypes.string,
};

TextWithLabel.contextTypes = {
  prefix: PropTypes.string,
};

export function CheckBoxWithLabel(props, context) {
  return (
    <div className={`checkbox ${props.className}`} onClick={props.onClick}>
    <input type="checkbox" name={_name(context, props.name)} id={_name(context, props.name)} value="1" data-testname={`${props.testName}-checkbox`} aria-label={props.ariaLabel}/>
    <label htmlFor={_name(context, props.name)} data-testname={`${props.testName}-wrapper`} dangerouslySetInnerHTML={{ __html: props.label }}></label>
  </div>
  );
}

CheckBoxWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  testName: PropTypes.string.isRequired,
};

CheckBoxWithLabel.contextTypes = {
  prefix: PropTypes.string,
};

function mapStateToFormProps(state) {
  return {
    urls: state.$$layout.get('urls'),
    token: state.$$layout.get('authenticityToken'),
    customDomain: state.$$layout.get('customDomain'),
  };
}

export const Form = connect(mapStateToFormProps)(_Form);

export function getAndHideFlushContainerErrors() {
  const flushContainer = document.querySelector('.my-notification .text')
  let errorsText = ''

  if (flushContainer) {
    errorsText = flushContainer.outerText
    document.querySelector('.my-notification').style.display = 'none'
  }

  return errorsText
}
