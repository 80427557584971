import { intervalToDuration } from 'date-fns';

import defaultSchool from '../../containers/schoolTemplates/default/School'
import graySchool from '../../containers/schoolTemplates/gray/School'
import greenSchool from '../../containers/schoolTemplates/green/School'
import purpleSchool from '../../containers/schoolTemplates/purple/School'
import BundleContainer from '../../containers/schoolTemplates/common/BundleContainer'
import BundlesContainer from '../../containers/schoolTemplates/default/BundlesContainer'

export const BUNDLE_HEADER_SELECTOR = '.school-bundles-header';


export const importBaseComponents = ( templateName ) => {
  const components = templateName === 'default' ? [BundlesContainer] : [BundleContainer];

  switch (templateName) {
    case 'gray':   components.push(graySchool);    break;
    case 'green':  components.push(greenSchool);   break;
    case 'purple': components.push(purpleSchool);  break;
    default:       components.push(defaultSchool); break;
  }

  return components;
}

export const importSchoolTemplates = (templateName) => {
  switch (templateName) {
    case 'gray': return graySchool; break;
    case 'green': return greenSchool; break;
    case 'purple': return purpleSchool; break;
    default: return defaultSchool; break;
  }
}

export const durationString = (durationInSeconds, i18n) => {
  const duration = intervalToDuration({ start: 0, end: durationInSeconds * 1000 });
  let result = '';

  if (duration.years > 0 || duration.months > 0 || duration.days > 0 || duration.hours > 10) {
    let rawHours = parseInt(durationInSeconds / 3600)
    return i18n.hours.replace('%{number}', rawHours);
  }

  if (duration.hours == 1) {
    result += i18n.hour;
  } else if (duration.hours === 2) {
    result += i18n.twoHours;
  } else if (duration.hours > 2) {
    result += i18n.hours.replace('%{number}', duration.hours);
  }

  if (duration.minutes === 0) {
    return result;
  } else if (duration.hours > 0) {
    result += i18n.and;
  }

  if (duration.minutes === 1) {
    result += i18n.minute;
  } else if (duration.minutes > 1) {
    result += i18n.minutes.replace('%{number}', duration.minutes);
  }

  return result;
}

export const getTextWithColor = (text = '', color = '') => {
  if (typeof text === 'string' && text.length && color.length) {
    text = text.replace('style="', `style="color:${color};`);
  }

  return text;
}

export const updateTinymceEditorAnchor = (text = '') => {
  if (typeof text === 'string' && text.length) {
    text = text.replace(/<a /g, '<a target="_blank"');
  }

  return text;
}

export const hexAndAlphaToRGBA = (hex = '#000', alpha = '0.5') => {
  hex = hex.replace(/[\s#]/g, '')

  if (hex.length === 3) {
    hex = hex.split('').map((char) => char + char).join('');
  }

  let [all, red, green, blue] = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  red = parseInt(red, 16)
  green = parseInt(green, 16)
  blue = parseInt(blue, 16)

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`
}

export const scrollToView = (selector = BUNDLE_HEADER_SELECTOR) => {
  const elementToView = document.querySelector(selector)

  if (elementToView) {
    setTimeout(() => {
      window.scroll({
        top: elementToView.offsetTop,
        left: 0,
        behavior: 'smooth'
      });
    }, 0)
  }
}

export const adjustHeaderFontSize = (selector) => {
  const headers = document.querySelectorAll(selector);
  headers.forEach((header) => {
    const computedFontSize = parseFloat(
      window.getComputedStyle(header).fontSize
    );
    if (computedFontSize > 28) {
      header.style.fontSize = "28px";
    }
  });
};

export const scrollToElement = (className) => {
  const element = document.querySelector(className);
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};
