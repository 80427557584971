import React from 'react';
import { connect } from 'react-redux';

import Container from './container';
import multiLayout from 'lib/multi_layout';
import LocalStorageManager from 'lib/localStorageManager';
import PasswordFormLogo from '../components/svg/password_form_logo';
import SchoolPasswordNew from '../components/forms/school_password_new';
import { Form, HiddenField, Input } from '../components/forms/form_for';
import { StudentTracker } from '../services/StudentTracker';

class PasswordNew extends Container {

  componentDidMount(prevProps) {
    this.trackResetPasswordEvent();
    StudentTracker.pageView( this.props.eventProps, this.props.ga4DisabledEventsCodes);
  }

  trackResetPasswordEvent = () => {
    if(LocalStorageManager.get('reset_password') === true){
      StudentTracker.track('reset_password', {});
      LocalStorageManager.remove('reset_password');
    }
  }

  setResetKey = () => {
    LocalStorageManager.save('reset_password', true);
  }

  render() {
    const { isExplicitSchool, schoolId, i18n } = this.props;

    if (isExplicitSchool) {
      return <SchoolPasswordNew {...this.props} />;
    } else {
      return (
        <div className="centered-container flex justify-center items-center md:pt-[165px] pt-[90px]">
          <div className="col">
            <div className="flex justify-center">
              <PasswordFormLogo fillColor={'#03D6F3'} />
            </div>

            <h3 className='text-center !mt-[10px] text-[30px] font-bold text-[#333333] leading-[15px]' dangerouslySetInnerHTML={{ __html: i18n.resetPassword.title }}></h3>
            <div className="flex justify-center !mt-[10px]">
              <p className="text-center text-[18px] font-light text-[#3F4140] w-[300px]" dangerouslySetInnerHTML={{ __html: i18n.resetPassword.description }}></p>
            </div>

            <Form for="user" action="user_password_path" method="POST" className='clear mt-[27px] w-full ml-0' onSubmit={this.setResetKey}>
              <HiddenField name="school_id" value={schoolId} />

              <div className="sclr-form-row">
                <div className='nm-left'>
                  <Input
                    className="input-field !px-[18px] text-base font-normal placeholder-[#333] directional"
                    customValidity={i18n.emailError}
                    id="email-field"
                    name="email"
                    aria-label={i18n.emailAriaLabel}
                    placeholder={i18n.email}
                    required={true}
                    type="email"
                  />
                </div>
              </div>

              <input
                type="submit"
                aria-label={i18n.resetPassword.submitButtonAriaLabel}
                className='cta-btn w-full mt-[20px] reset-password-btn'
                value={i18n.resetPassword.submitButton}
              />
            </Form>
          </div>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    schoolId: state.$$layout.get('school_id'),
    ga4DisabledEventsCodes: state.$$layout.get('ga4_disabled_events_codes'),
    eventProps: state.$$layout.get('event_props').toJS(),
    isExplicitSchool: state.$$school.get('explicit'),
    i18n: {
      ...state.$$i18n.getIn(['students', 'form']).toJS(),
      resetPassword: state.$$i18n.getIn(['students', 'resetPassword']).toJS()
    }
  };
}

const props = {
  lesson: {
    internalScroll: false,
    hideBottomPanel: true,
  },
};

export default multiLayout(props)(connect(mapStateToProps)(PasswordNew));
