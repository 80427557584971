import React from 'react';
import { connect } from 'react-redux';
import { StudentTracker } from '../services/StudentTracker';
import LocalStorageManager from '../../../lib/localStorageManager';

class AfterPay extends React.Component {

  componentDidMount() {
    if(this.props.purchaseDone === true){
      this.purchaseEvent(this.props);
    }
  }

  purchaseEvent = (props) => {
    if(LocalStorageManager.get('finalPrice') === null){
      return
    }
    StudentTracker.track('purchase', {
      'transaction_id': props.paymentId,
      'value': LocalStorageManager.get('finalPrice') || props.bundlePrice,
      'currency': 'ILS',
      'items': this.itemProps(props)
    });    
    LocalStorageManager.remove('couponName');
    LocalStorageManager.remove('couponDiscount');
    LocalStorageManager.remove('finalPrice');
  }

  itemProps = (props) => {
    let couponName = LocalStorageManager.get('couponName');
    let couponDiscount = LocalStorageManager.get('couponDiscount');
    let finalPrice = LocalStorageManager.get('finalPrice');
    let items = [
      {
        'item_id': `${props.bundleId}`,
        'item_name': props.bundleName,
        'item_brand': props.teacherName,
        'item_brand_id': props.teacherId,
        'item_category': props.schoolName,
        'item_category2': props.bundlePolicy,
        'coupon': couponName || props.couponName,
        'discount': couponDiscount || props.couponDiscount,   
        'price': finalPrice || props.bundlePrice,
        'currency': "ILS",
        'quantity': 1
      }
    ];
    return items;
  }

  render() {
    const { bundleName, i18n } = this.props;

    return (
      <div className='container'>
        <h1 style={{paddingTop: '20px'}} dangerouslySetInnerHTML={{
          __html: i18n.title.replace('%{course_name}', bundleName)
        }}></h1>

        <p style={{ padding: '20px' }} dangerouslySetInnerHTML={{
          __html: i18n.content
        }}></p>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bundleId: state.$$afterjoin.get('bundle_id'),
    bundleName: state.$$afterjoin.get('bundle_name'),
    bundlePrice: state.$$afterjoin.get('price'),
    i18n: state.$$i18n.getIn(['students', 'thankYou', 'schoolPayment']).toJS(),
    bundleId: state.$$afterjoin.get('bundle_id'),
    bundlePolicy: state.$$afterjoin.get('bundle_policy'),
    teacherId: state.$$afterjoin.get('teacher_id'),
    teacherName: state.$$afterjoin.get('teacher_name'),
    couponName: state.$$afterjoin.get('coupon_name'),
    couponDiscount: state.$$afterjoin.get('coupon_discount'),
    purchaseDone: state.$$afterjoin.get('purchase_done'),
    paymentId: state.$$afterjoin.get('payment_id'),
  };
}

export default connect(mapStateToProps)(AfterPay);
