import React from 'react';
import { connect } from 'react-redux';
import userNameHelper from 'lib/form_helpers';
import { Input } from './form_for';
import LocalStorageManager from 'lib/localStorageManager';
import LoginFormLogo from '../../components/svg/login_form_logo';

LoginForm.propTypes = {
  token: React.PropTypes.string,
  schoolId: React.PropTypes.number,
  loginUrl: React.PropTypes.string,
  passwordResetUrl: React.PropTypes.string,
  customDomain: React.PropTypes.bool.isRequired,
  embeddedForm: React.PropTypes.bool,
  inlineError: React.PropTypes.string,
};

function setLoginKey(){
  LocalStorageManager.save('signing_in', true);
}

function LoginForm(props) {
  const name = userNameHelper(props.customDomain);
  let url = new URL(window.location.href);
  let searchParams = new URLSearchParams(url.search);
  const isInactive = !!+searchParams.get('inactive');

  return (
    <div className='centered-container flex justify-center items-center md:pt-[165px] pt-[90px]'>
      <div className={`flex flex-col items-center gap-[24px] w-[335px]}`}>
        <div className='flex flex-col justify-center items-center gap-[13px]'>
          <LoginFormLogo fillColor={'#03D6F3'} />
          <h2 className='text-[#333] text-right md:text-[30px] text-[28px] font-bold leading-[24.142px] tracking-[0.409px] !mt-0'
              dangerouslySetInnerHTML={{ __html: props.i18n.login.title }}>
          </h2>
        </div>

        <form
          role="form"
          action={props.loginUrl}
          method="POST"
          className='max-w-[780px] inline-block w-full clear'
          onSubmit={setLoginKey}
        >
          {isInactive &&
            <div className='sclr-form-row'>
              <div className='nm-left error-block'>
                <text dangerouslySetInnerHTML={{ __html: props.i18n.login.inactiveStudent }}></text>
              </div>
              <div className='nm-right'></div>
            </div>
          }

          <input name="authenticity_token" type="hidden" value={props.token} />
          <input type="hidden" name={name('school_id')} value={props.schoolId} />

          <div className="sclr-form-row">
            <div className='nm-left'>
              <Input
                className="input-field !px-[18px] text-base font-normal placeholder-[#333] directional"
                customValidity={props.i18n.form.emailError}
                id="email-field"
                name={name('email')}
                aria-label={props.i18n.form.emailAriaLabel}
                placeholder={props.i18n.form.email}
                required={true}
                type="email"
              />
            </div>
          </div>

          <div className="sclr-form-row mt-[10px]">
            <div className='nm-left'>
              <input
                name={name('password')}
                type="password"
                className="input-field !px-[18px] text-base font-normal placeholder-[#333] !text-justify directional"
                id="password-field"
                autoComplete="off"
                aria-label={props.i18n.form.passwordAriaLabel}
                placeholder={props.i18n.form.password}
              />
            </div>
          </div>

          <div>
            <div className='checkbox md:m-0 mt-[3px]'>
              <a className='forgot-password-link !text-brand-link text-base leading-normal !font-normal tracking-wide'
                href={props.passwordResetUrl}
                arial-label={props.i18n.form.forgotPasswordAriaLabel}
                dangerouslySetInnerHTML={{ __html: props.i18n.form.forgotPassword }}>
              </a>
            </div>
          </div>

          <div className='mt-[42px] mb-0'>
            <input type='submit' className='cta-btn w-full' value={props.i18n.form.loginButton} />
          </div>

          {props.canbuy && !props.embeddedForm &&
            <div className='nm-login-actions distance-sm link-to-registration md:mt-[8px] mt-[24px] p-0 text-center tracking-wide' dangerouslySetInnerHTML={{
              __html: props.i18n.form.newUserLink.replace('%{target_link}', props.signupUrl)
            }}></div>
          }

        </form>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    canbuy: state.$$account.get('canbuy'),
    customDomain: state.$$layout.get('customDomain'),
    i18n: {
      form: state.$$i18n.getIn(['students', 'form']).toJS(),
      login: state.$$i18n.getIn(['students', 'login']).toJS(),
    },
    inlineError: state.$$layout.get('inline_error'),
    loginUrl: state.$$layout.getIn(['urls', 'login']),
    passwordResetUrl: state.$$layout.getIn(['urls', 'reset_password']),
    schoolId: state.$$layout.get('school_id'),
    signupUrl: state.$$layout.getIn(['urls', 'signup']),
    token: state.$$layout.get('authenticityToken')
  };
}

export default connect(mapStateToProps)(LoginForm);
