import React from 'react';
import { connect } from 'react-redux';
import LoginForm from '../components/forms/login_form';
import OfferToBuy from '../components/offer_to_buy';
import MultiLayout from '../components/multi_layout';
import { StudentTracker } from '../services/StudentTracker';
import LocalStorageManager from '../../../lib/localStorageManager';

function UserExists(props) {
  return (
    <div className="content-inner">
      <div className="iconed-block">
        <div className="iconed-block-inner">
          <div className="col-set col-set-bordered">
            <div className="col">

              <h1 style={{paddingTop: '20px'}} dangerouslySetInnerHTML={{
                __html: props.i18n.existingStudent.title
              }}></h1>

              <p style={{ padding: '20px' }} dangerouslySetInnerHTML={{
                __html: props.i18n.existingStudent.content
              }}></p>

              <LoginForm embeddedForm={true} />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ThanksForJoiningSchool(props) {
  return (
    <div className="content-inner">

      <div className="iconed-block">
        <div className="sch-icon sch-smily"></div>

        <div className="iconed-block-inner">
          <h3 className="text-thin" dangerouslySetInnerHTML={{
            __html: props.i18n.school.title
          }}></h3>

          <p className="sub-heading" dangerouslySetInnerHTML={{
            __html: props.i18n.school.content.replace('%{school_name}', props.schoolName)
          }}></p>

          {props.wantCourse && (
            <p dangerouslySetInnerHTML={{__html: props.i18n.school.message}}></p>
          )}
        </div>
      </div>
    </div>
  );
}


function ThanksForJoining(props) {
  return (
    <div className="content-inner">

      <div className="iconed-block">
        <div className="sch-icon sch-smily"></div>

        <div className="iconed-block-inner">
          <h3 className="text-thin" dangerouslySetInnerHTML={{
            __html: props.i18n.course.title
          }}></h3>

          <p className="sub-heading" dangerouslySetInnerHTML={{
            __html: props.i18n.course.content.replace('%{course_name}', props.bundleName)
          }}></p>
        </div>
      </div>
    </div>
  );
}

function ThanksForCoursePayment(props) {
  return (
    <div className="content-inner">

      <div className="iconed-block">
        <div className="sch-icon sch-smily"></div>

        <div className="iconed-block-inner">
          <h3 className="text-thin" dangerouslySetInnerHTML={{
            __html: props.i18n.coursePayment.title
          }}></h3>

          <p className="sub-heading" dangerouslySetInnerHTML={{
            __html: props.i18n.coursePayment.content.replace('%{course_name}', props.bundleName)
          }}></p>
        </div>
      </div>
    </div>
  );
}

class AfterJoin extends React.Component {
  static propTypes = {
    error: React.PropTypes.bool.isRequired,
  };

  componentDidMount() {
    if(this.props.purchaseDone === true){
      this.purchaseEvent(this.props);
    }
    if (this.props.customThankyouPage) {
      window.location.href = this.props.customThankyouPage;
    }
    if (!this.props.error){
      if(this.props.newSignUp === true){
        this.trackSignUp(this.props);
      }
    }
  }

  trackSignUp = (props) => {
    StudentTracker.track('sign_up', {
      'item_id': props.bundleId,
      'item_name': props.bundleName,
      'method': 'email',
      'item_brand': props.teacherName,
      'item_brand_id': props.teacherId,
      'item_category': props.schoolName,
      'item_category2': props.bundlePolicy
    });
  }

  purchaseEvent = (props) => {
    if(LocalStorageManager.get('finalPrice') === null){
      return
    }
    StudentTracker.track('purchase', {
      'transaction_id': props.paymentId,
      'value': LocalStorageManager.get('finalPrice'),
      'currency': 'ILS',
      'items': this.itemProps(props)
    });    
    LocalStorageManager.remove('couponName');
    LocalStorageManager.remove('couponDiscount');
    LocalStorageManager.remove('finalPrice');
  }

  itemProps = (props) => {
    let couponName = LocalStorageManager.get('couponName');
    let couponDiscount = LocalStorageManager.get('couponDiscount');
    let finalPrice = LocalStorageManager.get('finalPrice');
    let items = [
      {
        'item_id': `${props.bundleId}`,
        'item_name': props.bundleName,
        'item_brand': props.teacherName,
        'item_brand_id': props.teacherId,
        'item_category': props.schoolName,
        'item_category2': props.bundlePolicy,
        'coupon': couponName || props.couponName,
        'discount': couponDiscount || props.couponDiscount,   
        'price': finalPrice || props.discountedPrice,
        'currency': "ILS",
        'quantity': 1
      }
    ];
    return items;
  }

  render() {
    const props = this.props;

    if (props.error) {
      return (
        <MultiLayout hideBottomPanel={true} internalScroll={false}>
          <UserExists {...props} /> :
        </MultiLayout>
      );
    } else if (props.waitingPayment) {
      return (
        <MultiLayout hideBottomPanel={true} internalScroll={false}>
          <OfferToBuy {...props} />
        </MultiLayout>);
    } else if (props.customThankyouPage) {
      return <p dangerouslySetInnerHTML={{__html: props.i18n.waiting}}></p>;
    } else if (props.bundlePrice > 0) {
      return <ThanksForCoursePayment {...props} />;
    } else if (props.schoolName) {
      return <ThanksForJoiningSchool {...props} />;
    } else {
      return <ThanksForJoining {...props} />;
    }
  }
}

function mapStateToProps(state) {
  return {
    bundleId: state.$$afterjoin.get('bundle_id'),
    bundleName: state.$$afterjoin.get('bundle_name'),
    bundlePrice: state.$$afterjoin.get('bundle_price') || 0,
    bundlePolicy: state.$$afterjoin.get('bundle_policy'),
    customThankyouPage: state.$$afterjoin.get('custom_thankyou_url'),
    error: state.$$afterjoin.get('error'),
    implicitSchool: state.$$afterjoin.get('implicit_school'),
    schoolName: state.$$afterjoin.get('school_name'),
    waitingPayment: state.$$afterjoin.get('waiting_payment'),
    wantCourse: state.$$afterjoin.get('want_course'),
    teacherId: state.$$afterjoin.get('teacher_id'),
    teacherName: state.$$afterjoin.get('teacher_name'),
    i18n: state.$$i18n.getIn(['students', 'thankYou']).toJS(),
    couponName: state.$$afterjoin.get('coupon_name'),
    couponDiscount: state.$$afterjoin.get('coupon_discount'),
    purchaseDone: state.$$afterjoin.get('purchase_done'),
    paymentId: state.$$afterjoin.get('payment_id'),
    newSignUp: state.$$afterjoin.get('new_sign_up'),
    discountedPrice: state.$$afterjoin.get('discounted_price') || state.$$afterjoin.get('bundle_price') || 0,
  };
}

export default connect(mapStateToProps)(AfterJoin);
