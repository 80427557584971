import React from 'react';
import { connect } from 'react-redux';
import Lesson from './lesson';

class JoinBundleLayout extends React.Component {
  render() {
    const { newUserButtonIcon, description, audience, instructor, i18n } = this.props;
    const { Header, Content } = this.props;
    return (
      <Lesson hideBottomPanel={true} internalScroll={false}>
        <div
          className="content-inner"
        >

          <div className="iconed-block">
            <div className={`sch-icon sch sch-${newUserButtonIcon}`} aria-hidden="true" />

            <div className="iconed-block-inner">

              <Header />

              <div className="col-set col-set-bordered">
                <section className="col">
                  <Content />
                </section>
                <section className="col course-info-item hide-mobile">
                  <div>
                    <h3 className="text-bold"
                      dangerouslySetInnerHTML={{ __html: i18n.about }}></h3>
                    <p dangerouslySetInnerHTML={{ __html: description }} />
                  </div>
                </section>
              </div>

            </div>
          </div>

          <hr className="hr-indent-sm " />

          <div className='iconed-block'>
            <div className="col-set">
              <section className="col course-info-item hide-desktop">
                <h3 className="text-bold" dangerouslySetInnerHTML={{ __html: i18n.about }}></h3>
                <span dangerouslySetInnerHTML={{ __html: description }} />
              </section>
              <section className="col course-info-item">
                <h4 className="text-bold" dangerouslySetInnerHTML={{ __html: i18n.audience }}></h4>
                <span dangerouslySetInnerHTML={{ __html: audience }} />
              </section>
              <section className="col course-info-item">
                <h4 className="text-bold" dangerouslySetInnerHTML={{ __html: i18n.aboutInstructor }}></h4>
                <span dangerouslySetInnerHTML={{ __html: instructor }}></span>
              </section>
            </div>
          </div>
        </div>
      </Lesson>
    );
  }
}

function mapStateToProps(state) {
  return {
    newUserButtonIcon: state.$$layout.getIn(['default_bundle_props', 'paid_for']) ? 'cart' : 'registration',
    instructor: state.$$layout.getIn(['default_bundle_props', 'instructor']).replace(/\n/g, '<br />'),
    audience: state.$$layout.getIn(['default_bundle_props', 'audience']).replace(/\n/g, '<br />'),
    description: state.$$layout.getIn(['default_bundle_props', 'description']).replace(/\n/g, '<br />'),
    i18n: state.$$i18n.getIn(['students', 'course']).toJS()
  };
}

export default connect(mapStateToProps)(JoinBundleLayout);
